import { cn } from '@rouvydev/web-components/utils'
import { motion } from 'framer-motion'
import { Link } from '@remix-run/react'
import { cardVariants } from '@rouvydev/web-components/content'

export interface JokerCardProps {
  title: string
  description: string
  url?: string | null
  cover?: string | null
  className?: string
}

export function JokerCard({
  title,
  description,
  url,
  cover,
  className,
}: JokerCardProps) {
  return (
    <article
      className={cn(
        'relative flex bg-background-canvas',
        cardVariants.md.size,
        className,
      )}
    >
      <div
        className="relative flex h-full flex-grow flex-col justify-end bg-cover bg-top px-6 pb-[86px]"
        style={{
          backgroundImage: `linear-gradient(360deg, rgb(17, 7, 40) 0%, rgba(17, 7, 40, 0) 71.59%), url(${cover})`,
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="flex flex-col gap-4"
        >
          <span className="text-[12px] leading-[16px] text-global-white">
            {description}
          </span>
          {url ? (
            <Link to={url}>
              <h3 className="stretched-link mb-4 font-display text-[28px] uppercase italic leading-[36px] text-global-white">
                {title}
              </h3>
            </Link>
          ) : (
            <h3 className="stretched-link mb-4 font-display text-[28px] uppercase italic leading-[36px] text-global-white">
              {title}
            </h3>
          )}
        </motion.div>
      </div>
    </article>
  )
}
