import type { HTMLAttributes, ReactNode } from 'react'
import { cn } from '@rouvydev/web-components/utils'

type Size = 'small' | 'medium'

type Color = 'purple' | 'black' | 'stravaOrange' | 'yellow' | 'darkBlue'

type Props = {
  color?: Color
  size?: Size
  children?: ReactNode
  className?: string
} & HTMLAttributes<HTMLDivElement>

export const sizes: Record<Size, string> = {
  medium: 'px-2 py-1 text-body-14',
  small: 'px-1 py-0.5 text-body-12 h-5 leading-3',
}

export const colors: Record<Color, string> = {
  purple: 'bg-global-electric-indigo-700 text-global-white',
  black: 'bg-global-black text-global-white',
  stravaOrange: 'bg-[#FC4C02] text-global-white',
  yellow: 'bg-global-jonquil-yellow-700 text-global-black',
  darkBlue: 'bg-global-dark-ink-blue-200 text-global-white',
}

export function Badge(props: Props) {
  const { color, size, children, ...rest } = props
  const base = 'inline-flex items-center justify-center text-center px-2 py-1'
  const colorStyles = color ? colors[color] : colors['purple']
  const sizeStyles = size ? sizes[size] : sizes['small']

  return (
    <div
      {...rest}
      className={cn(base, colorStyles, sizeStyles, props.className)}
      data-testid="badge"
    >
      {children}
    </div>
  )
}
